import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { LançamentosContext } from 'contexts/LançamentosContext';
import { toastWarning } from 'utils/toast';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import DeleteModal from './Modals/DeleteModal';
import ConciliaçãoModal from './Modals/ConciliaçãoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Lançamentos = () => {
  const rotina = 'Lançamentos';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { state } = useLocation();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { lançamentos, getLançamentos, getLoading } =
    useContext(LançamentosContext);
  const defaultValues = state?.id
    ? { ...lançamentos?.filter, conta_id: [state?.id] }
    : lançamentos?.filter;
  const { control, getValues, reset } = useForm({ defaultValues });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getLançamentos({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar',
      icon: 'add',
      action: () =>
        navigate('/app/Lancamentos/Gerar', {
          state: { conta_id: getValues()?.conta_id[0] },
        }),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: lançamentos?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: lançamentos?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={lançamentos?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Conciliar',
      icon: 'join_full',
      action: (selected) => {
        const block = selected?.find((f) => f?.conciliar === 'NAO');
        const block2 = selected?.some((s) => s?.conciliado !== 'NAO');
        if (Boolean(block)) {
          return toastWarning(`ID ${block?.id} não pode ser conciliado`);
        }
        if (Boolean(block2)) {
          return toastWarning('Selecione somente itens ainda não conciliados');
        }
        openModal(<ConciliaçãoModal itens={selected} callback={loadGrid} />);
      },
      // openModal(<ConciliaçãoModal item={row} callback={loadGrid} />),
    },
  ];

  const rowOptions = [
    {
      name: 'Detalhes',
      icon: 'visibility',
      menu: true,
      action: ({ row }) =>
        navigate('/app/Lancamentos/Detalhes', { state: row }),
    },
    {
      name: 'Editar',
      icon: 'edit',
      show: ({ row }) => row?.alterar === 'SIM',
      action: ({ row }) => navigate('/app/Lancamentos/Gerar', { state: row }),
    },
    {
      name: 'Excluir / Estornar',
      icon: 'delete',
      show: ({ row }) => row?.excluir === 'SIM',
      action: ({ row }) =>
        openModal(<DeleteModal item={row} callback={loadGrid} />),
    },
    {
      name: 'Conciliar',
      icon: 'join_full',
      menu: true,
      show: ({ row }) => row?.conciliar === 'SIM',
      action: ({ row }) =>
        openModal(<ConciliaçãoModal item={row} callback={loadGrid} />),
    },
    {
      name: 'Demonstrativo do Movimento Financeiro',
      icon: 'print',
      menu: true,
      action: ({ row }) =>
        getURLRelatorio({
          data: {
            codigo: 'FINMOV0001',
            mov: row?.financeiro_movimento_id,
          },
          cb: loadGrid,
        }),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} colunas={lançamentos?.colunas} />
      <Card>
        <Grid
          grid={lançamentos}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={lançamentos} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Lançamentos;
