import { createContext, useState } from 'react';
import api from 'services/api';
import moment from 'moment';

const filter = {
  like: '',
  id: '',
  entidade: [],
  dtvenc: {
    i: null,
    f: null,
  },
  dtlanc: {
    i: moment().subtract(10, 'days').format('YYYY-MM-DD'),
    f: null,
  },
  documento: '',
  parcela: '',
  valor: '',
  valor_outros: '',
  forma_pagto: [],
  vendedor: [],
  filial: [],
  dtemissao: {
    i: null,
    f: null,
  },
  observacao: '',
  origem: '',
};

export const RecebimentosBaixadosContext = createContext();

export const RecebimentosBaixadosProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [recebimentosBaixados, setRecebimentosBaixados] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getRecebimentosBaixados = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: recebimentosBaixados?.filter,
        page: recebimentosBaixados?.page,
        size: recebimentosBaixados?.size,
        order: recebimentosBaixados?.order,
        ...payload,
      });
      setRecebimentosBaixados((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <RecebimentosBaixadosContext.Provider
      value={{
        getLoading,
        getRecebimentosBaixados,
        recebimentosBaixados,
      }}
    >
      {children}
    </RecebimentosBaixadosContext.Provider>
  );
};
