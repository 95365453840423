import { createContext, useState } from 'react';
import api from 'services/api';
import moment from 'moment';

const filter = {
  like: '',
  id: '',
  entidade: [],
  dtvenc: {
    i: null,
    f: moment().format('YYYY-MM-DD'),
  },
  documento: '',
  parcela: '',
  valor: '',
  valor_baixado: '',
  valor_pendente: '',
  valor_outros: '',
  forma_pagto: [],
  vendedor: [],
  filial: [],
  dtemissao: {
    i: null,
    f: null,
  },
  observacao: '',
  origem: '',
};

export const RecebimentosPendentesContext = createContext();

export const RecebimentosPendentesProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [recebimentosPendentes, setRecebimentosPendentes] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getRecebimentosPendentes = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: recebimentosPendentes?.filter,
        page: recebimentosPendentes?.page,
        size: recebimentosPendentes?.size,
        order: recebimentosPendentes?.order,
        ...payload,
      });
      setRecebimentosPendentes((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <RecebimentosPendentesContext.Provider
      value={{
        getLoading,
        getRecebimentosPendentes,
        recebimentosPendentes,
      }}
    >
      {children}
    </RecebimentosPendentesContext.Provider>
  );
};
