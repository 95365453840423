import React, { useContext } from 'react';
import { DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useModal } from 'components/Modals';
import { LançamentosContext } from 'contexts/LançamentosContext';
import InputMask from 'components/InputMask';
import Button from 'components/Button';
import moment from 'moment';

const ConciliaçãoModal = ({ item, itens = [], callback }) => {
  const defaultValues = { dtlanc: moment().format('YYYY-MM-DD') };
  const { control, handleSubmit } = useForm({ defaultValues });
  const { closeModal } = useModal();
  const { postLançamento, postLançamentos, postLoading } =
    useContext(LançamentosContext);

  const onSubmit = (values) => {
    const cb = () => {
      closeModal();
      if (callback) {
        callback();
      }
    };
    if (Boolean(itens?.length)) {
      const data = itens?.map((i) => ({
        id: i?.id,
        conciliado: 'SIM',
        ...values,
      }));
      postLançamentos({ data, cb });
    } else {
      const data = { cxlancto: { id: item?.id, ...values, conciliado: 'SIM' } };
      postLançamento({ data, cb });
    }
  };

  return (
    <>
      <DialogTitle>Conciliar Lançamento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <InputMask
              type="date"
              name="dtlanc"
              control={control}
              label="Data de Lançamento"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)} loading={postLoading}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default ConciliaçãoModal;
